<template>
  <Layout ref="main">
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-title p-3 py-2 border-bottom">
            <div class="d-lg-flex align-items-center">
              <div class="d-flex align-items-center mb-2 mb-lg-0">
                <div class="me-2">
                  <i class="uil uil-chart display-6 text-primary"></i>
                </div>
                <div class="">
                  <h4 class="fw-medium font-size-20 text-dark mb-0">
                    {{defaultName}} {{ title }}
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row align-items-end mb-3">
              <div class="col-12 col-md-4">
                <label class="mb-1">Currency</label>
                <multiselect v-model="selectedPaymentGatewayCurrency" :options="paymentGatewayCurrency" label="name" track-by="name"  placeholder="Select a Currency" :close-on-select="true" :allow-empty="false" deselect-label="" :show-on-select="true" :disabled="loading" @input="getEarningsBalance()">
                  <template slot="singleLabel" slot-scope="props">
                    <span v-if="props.option.value!==''">
                      <span class="option__title">{{ props.option.name }} ({{ props.option.value }})</span>
                    </span>
                  </template>
                  <template slot="option" slot-scope="props">
                    <span v-if="props.option.name!==''" class="option__title">{{ props.option.name }}</span>
                  </template>
                  
                  <span slot="noResult">Oops! No currency found. </span>
                </multiselect>
              </div>
              <div class="col-12 col-md-4">
                <label class="mb-1">Date Range</label>
                <date-picker
                  v-model="date_range"
                  type="date"
                  range
                  value-type="format"
                  format="YYYY-MM-DD"
                  placeholder="Select Date Range"
                  :disabled="disable_date"
                  :disabled-date="notBeforeToday"
                  :clearable="false"
                  :editable="false"
                  :shortcuts="shortcuts"
                  @change="DateChanged()"
                ></date-picker>
              </div>
            </div>
            <div class="col-12 mb-3">
              <div
              class="table-responsive text-nowrap font-size-14 position-relative d-none"
            >
              <table
                id="freeze"
                class="table table-bordered mb-0 align-middle min-width-760 text-end custom-report"
                :class="{'table-hover': earnings_report.length>0 && !loading}"
              >
                <thead class="text-uppercase custom_top_data_bar font-size-12">
                  <tr>
                    <th class="text-start">Currency</th>
                    <th class="text-end">Total Payin (Count)/Earning</th>
                    <th class="text-end">Total E-Wallet (Count)/Earning</th>
                    <th class="text-end">Total Payout (Count)/Earning</th>
                    <th class="text-end">Total Settlement (Count)/Earning</th>
                    <th class="text-end">Total Reseller Withdrawal (Count)/Earning</th>
                    <th class="text-end">Total All Earnings</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="!earnings_report.length && !loading">
                    <td colspan="7" height="300" class="text-center text-muted">
                      <i class="uil uil-chart me-1"></i> No Data
                      Available
                    </td>
                  </tr>
                  <tr v-if="loading">
                    <td colspan="7" class="text-center text-muted" height="400">
                      <div
                        class="spinner-border text-secondary my-2"
                        role="status"
                      >
                        <span class="sr-only">Loading...</span>
                      </div>
                      <br />
                      <div>Loading...</div>
                    </td>
                  </tr>

                  <tr  v-for="(value, index) in earnings_report" :key="index" v-show="!loading">
                    <td class="text-center fw-medium text-info"> {{ selectedPaymentGatewayCurrency.value }}
                    </td>
                    <td>
                      <div class="bg-soft-success p-1 rounded mb-1">
                      <span v-if="value.total_payin>0">{{ convertCurrencyFormat(value.total_payin,true) }} </span>
                      <span v-else>{{ convertCurrencyFormat(value.total_payin,true) }} </span> 
                      ({{ convertCurrencyFormat(value.total_payin_count,false) }})
                      </div>
                      <div class="bg-soft-secondary p-1 rounded">
                          <div class="font-size-12">Earning</div>
                          <div v-if="value.total_payin_earn>0">{{ convertCurrencyFormat(value.total_payin_earn,true) }}</div>
                          <div v-else>{{ convertCurrencyFormat(value.total_payin_earn,true) }}</div>
                      </div>
                    </td>
                    <td>
                      <div class="bg-soft-success p-1 rounded mb-1">
                      <span v-if="value.total_ewallet>0">{{ convertCurrencyFormat(value.total_ewallet,true) }} </span>
                      <span v-else>{{ convertCurrencyFormat(value.total_ewallet,true) }} </span> 
                      ({{ convertCurrencyFormat(value.total_ewallet_count,false) }})
                      </div>
                      <div class="bg-soft-secondary p-1 rounded">
                          <div class="font-size-12">Earning</div>
                          <div v-if="value.total_ewallet_earn>0">{{ convertCurrencyFormat(value.total_ewallet_earn,true) }}</div>
                          <div v-else>{{ convertCurrencyFormat(value.total_ewallet_earn,true) }}</div>
                      </div>
                    </td>
                    <td>
                      <div class="bg-soft-danger p-1 rounded mb-1">
                      <span v-if="value.total_payout>0">{{ convertCurrencyFormat(value.total_payout,true) }} </span>
                      <span v-else>{{ convertCurrencyFormat(value.total_payout,true) }} </span> 
                      ({{ convertCurrencyFormat(value.total_payout_count,false) }})
                      </div>
                      <div class="bg-soft-secondary p-1 rounded">
                          <div class="font-size-12">Earning</div>
                          <div v-if="value.total_payout_earn>0">{{ convertCurrencyFormat(value.total_payout_earn,true) }}</div>
                          <div v-else>{{ convertCurrencyFormat(value.total_payout_earn,true) }}</div>
                      </div>
                    </td>
                    <td>

                      <div class="bg-soft-danger p-1 rounded mb-1">
                      <span v-if="value.total_settlement>0">{{ convertCurrencyFormat(value.total_settlement,true) }} </span>
                      <span v-else>{{ convertCurrencyFormat(value.total_settlement,true) }} </span> 
                      ({{ convertCurrencyFormat(value.total_settlement_count,false) }})
                      </div>
                      <div class="bg-soft-secondary p-1 rounded">
                          <div class="font-size-12">Earning</div>
                          <div v-if="value.total_settlement_earn>0">{{ convertCurrencyFormat(value.total_settlement_earn,true) }}</div>
                          <div v-else>{{ convertCurrencyFormat(value.total_settlement_earn,true) }}</div>
                      </div>

                    </td>
                    <td>
                      <div class="bg-soft-orange p-1 rounded mb-1">
                      <span v-if="value.total_withdraw_amount>0">{{ convertCurrencyFormat(value.total_withdraw_amount,true) }} </span>
                      <span v-else>{{ convertCurrencyFormat(value.total_withdraw_amount,true) }} </span> 
                      ({{ convertCurrencyFormat(value.total_withdraw_amount_count,false) }})
                      </div>
                      <div class="bg-soft-secondary p-1 rounded">
                          <div class="font-size-12">Earning</div>
                          <div v-if="value.total_withdraw_amount_earn>0">{{ convertCurrencyFormat(value.total_withdraw_amount_earn,true) }}</div>
                          <div v-else>{{ convertCurrencyFormat(value.total_withdraw_amount_earn,true) }}</div>
                      </div>
                    </td>
                    <td class="align-middle">
                      <div class="bg-soft-primary h-100 p-1 py-2 rounded">
                        <div v-if="value.total_all_earn>0">{{ convertCurrencyFormat(value.total_all_earn,true) }}</div>
                        <div v-else>{{ convertCurrencyFormat(value.total_all_earn,true) }}</div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              </div>
            </div>
            <div class="col-12 mb-3">
              <div
              class="table-responsive text-nowrap font-size-14 position-relative"
            >
              <table
                id="freeze"
                class="table table-striped table-bordered mb-0 align-middle min-width-760 text-end custom-report"
                :class="{'': earnings_report.length>0 && !loading}"
              >
                <thead class="text-uppercase custom_top_data_bar font-size-12">
                  <tr>
                    <th class="text-start">Type</th>
                    <th class="text-center">Count</th>
                    <th class="text-end">Total Volume ({{ selectedPaymentGatewayCurrency.value }})</th>
                    <th class="text-end">Earning ({{ selectedPaymentGatewayCurrency.value }})</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="!earnings_report.length && !loading">
                    <td colspan="7" height="300" class="text-center text-muted">
                      <i class="uil uil-chart me-1"></i> No Data
                      Available
                    </td>
                  </tr>
                  
                  <tr v-if="loading">
                    <td colspan="7" class="text-center text-muted" height="400">
                      <div
                        class="spinner-border text-secondary my-2"
                        role="status"
                      >
                        <span class="sr-only">Loading...</span>
                      </div>
                      <br />
                      <div>Loading...</div>
                    </td>
                  </tr>

                  </tbody>
                  <tbody v-show="!loading" v-if="earnings_report.length">
                    <tr>
                      <td class="text-start">Total Payin</td>
                      <td class="text-center">
                        <div class=" p-1 rounded mb-1">
                          {{ convertCurrencyFormat(earnings_report[0].total_payin_count,false) }}
                        </div>
                      </td>
                      <td class="text-end">
                        <div class="p-1 rounded mb-1">
                          <span v-if="earnings_report[0].total_payin>0">{{ convertCurrencyFormat(earnings_report[0].total_payin,true) }} </span>
                          <span v-else>{{ convertCurrencyFormat(earnings_report[0].total_payin,true) }} </span> 
                        </div>

                      </td>
                      <td class="text-end">
                        <div class=" p-1 rounded mb-1">
                          <span v-if="earnings_report[0].total_payin_earn>0">{{ convertCurrencyFormat(earnings_report[0].total_payin_earn,true) }} </span>
                          <span v-else>{{ convertCurrencyFormat(earnings_report[0].total_payin_earn,true) }} </span> 
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td class="text-start">Total E-wallet</td>
                      <td class="text-center">
                        <div class=" p-1 rounded mb-1">
                          {{ convertCurrencyFormat(earnings_report[0].total_ewallet_count,false) }}
                        </div>
                      </td>
                      <td class="text-end">
                        <div class=" p-1 rounded mb-1">
                          <span v-if="earnings_report[0].total_ewallet>0">{{ convertCurrencyFormat(earnings_report[0].total_ewallet,true) }} </span>
                          <span v-else>{{ convertCurrencyFormat(earnings_report[0].total_ewallet,true) }} </span> 
                        </div>

                      </td>
                      <td class="text-end">
                        <div class=" p-1 rounded mb-1">
                          <span v-if="earnings_report[0].total_ewallet_earn>0">{{ convertCurrencyFormat(earnings_report[0].total_ewallet_earn,true) }} </span>
                          <span v-else>{{ convertCurrencyFormat(earnings_report[0].total_ewallet_earn,true) }} </span> 
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td class="text-start">Total Payout</td>
                      <td class="text-center">
                        <div class=" p-1 rounded mb-1">
                          {{ convertCurrencyFormat(earnings_report[0].total_payout_count,false) }}
                        </div>
                      </td>
                      <td class="text-end">
                        <div class=" p-1 rounded mb-1">
                          <span v-if="earnings_report[0].total_payout>0">{{ convertCurrencyFormat(earnings_report[0].total_payout,true) }} </span>
                          <span v-else>{{ convertCurrencyFormat(earnings_report[0].total_payout,true) }} </span> 
                        </div>

                      </td>
                      <td class="text-end">
                        <div class=" p-1 rounded mb-1">
                          <span v-if="earnings_report[0].total_payout_earn>0">{{ convertCurrencyFormat(earnings_report[0].total_payout_earn,true) }} </span>
                          <span v-else>{{ convertCurrencyFormat(earnings_report[0].total_payout_earn,true) }} </span> 
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td class="text-start">Total Settlement</td>
                      <td class="text-center">
                        <div class=" p-1 rounded mb-1">
                          {{ convertCurrencyFormat(earnings_report[0].total_settlement_count,false) }}
                        </div>
                      </td>
                      <td class="text-end">
                        <div class="p-1 rounded mb-1">
                          <span v-if="earnings_report[0].total_settlement>0">{{ convertCurrencyFormat(earnings_report[0].total_settlement,true) }} </span>
                          <span v-else>{{ convertCurrencyFormat(earnings_report[0].total_settlement,true) }} </span> 
                        </div>

                      </td>
                      <td class="text-end">
                        <div class=" p-1 rounded mb-1">
                          <span v-if="earnings_report[0].total_settlement_earn>0">{{ convertCurrencyFormat(earnings_report[0].total_settlement_earn,true) }} </span>
                          <span v-else>{{ convertCurrencyFormat(earnings_report[0].total_settlement_earn,true) }} </span> 
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td class="text-start">Total Reseller Withdrawal ({{defaultName}} Comm)</td>
                      <td class="text-center">
                        <div class="p-1 rounded mb-1">
                          {{ convertCurrencyFormat(earnings_report[0].total_withdraw_amount_count,false) }}
                        </div>
                      </td>
                      <td class="text-end">
                        <div class="p-1 rounded mb-1">
                          <span v-if="earnings_report[0].total_withdraw_amount>0">{{ convertCurrencyFormat(earnings_report[0].total_withdraw_amount,true) }} </span>
                          <span v-else>{{ convertCurrencyFormat(earnings_report[0].total_withdraw_amount,true) }} </span> 
                        </div>

                      </td>
                      <td class="text-end">
                        <div class=" p-1 rounded mb-1">
                          <span v-if="earnings_report[0].total_withdraw_amount_earn>0">{{ convertCurrencyFormat(earnings_report[0].total_withdraw_amount_earn,true) }} </span>
                          <span v-else>{{ convertCurrencyFormat(earnings_report[0].total_withdraw_amount_earn,true) }} </span> 
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td class="text-start bg-soft-dark" colspan="3">Total Earning ({{ selectedPaymentGatewayCurrency.value }})</td>
                      <td class="text-end bg-soft-success">
                        <div class=" h-100 p-1 rounded font-size-15">
                        <div v-if="earnings_report[0].total_all_earn>0">{{ convertCurrencyFormat(earnings_report[0].total_all_earn,true) }}</div>
                        <div v-else>{{ convertCurrencyFormat(earnings_report[0].total_all_earn,true) }}</div>
                      </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="col-12 text-muted"> 
              <div class="font-size-13" v-if="earnings_report.length>0">Report Last Updated: {{earnings_report[0].last_report_date}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 d-none">
        <div class="card">
          <div class="card-title p-3 py-2 border-bottom">
            <div class="d-lg-flex align-items-center">
              <div class="d-flex align-items-center mb-2 mb-lg-0">
                <div class="me-2">
                  <i class="uil uil-chart display-6 text-primary"></i>
                </div>
                <div class="">
                  <h4 class="fw-medium font-size-20 text-dark mb-0">
                    System {{ title }}
                  </h4>
                  <small class="d-block font-size-13 text-muted fw-normal">System {{ title }} </small>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="col-12">
              <div
              class="table-responsive text-nowrap font-size-14 position-relative"
            >
              <table
                id="freeze"
                class="table mb-0 align-middle min-width-760 text-end"
                :class="{'table-hover': system_balance.length>0 && !loading2}"
              >
                <thead class="text-uppercase custom_top_data_bar font-size-12">
                  <tr>
                    <th class="text-start">Currency</th>
                    <th class="text-start">Last Updated</th>
                    <th class="text-end">Merchant Balance</th>
                    <th class="text-end">Reseller Balance</th>
                    <th class="text-end">Company Profit</th>
                    <th class="text-end">Balance In All PG</th>
                    <th class="text-end">Cash In Hand</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="!system_balance.length && !loading2">
                    <td colspan="7" height="300" class="text-center text-muted">
                      <i class="uil uil-chart me-1"></i> No Data
                      Available
                    </td>
                  </tr>
                  <tr v-if="loading2">
                    <td colspan="7" class="text-center text-muted" height="400">
                      <div
                        class="spinner-border text-secondary my-2"
                        role="status"
                      >
                        <span class="sr-only">Loading...</span>
                      </div>
                      <br />
                      <div>Loading...</div>
                    </td>
                  </tr>

                  <tr v-for="(value, index) in system_balance" :key="index" v-show="!loading2">
                    <td class="text-start fw-medium text-info"> {{ value.currency }}
                    </td>
                    <td class="text-muted text-start">
                      {{ value.last_update }}
                    </td>
                    <td class="bg-soft-danger">
                      <div v-if="value.merchant_balance>0">{{ convertCurrencyFormat(value.merchant_balance,true) }}</div>
                      <div v-else>{{ convertCurrencyFormat(value.merchant_balance,true) }}</div>
                    </td>
                    <td class="bg-soft-danger">
                      <div v-if="value.reseller_balance>0">{{ convertCurrencyFormat(value.reseller_balance,true) }}</div>
                      <div v-else>{{ convertCurrencyFormat(value.reseller_balance,true) }}</div>
                    </td>
                    <td class="bg-soft-success">
                      <div v-if="value.company_profit>0">{{ convertCurrencyFormat(value.company_profit,true) }}</div>
                      <div v-else>{{ convertCurrencyFormat(value.company_profit,true) }}</div>
                    </td>
                    <td class="bg-soft-info">
                      <div v-if="value.balance_in_all_pg>0">{{ convertCurrencyFormat(value.balance_in_all_pg,true) }}</div>
                      <div v-else>{{ convertCurrencyFormat(value.balance_in_all_pg,true) }}</div>
                    </td>
                    <td class="bg-soft-primary">
                      <div v-if="value.cash_in_hand>0">{{ convertCurrencyFormat(value.cash_in_hand,true) }}</div>
                      <div v-else>{{ convertCurrencyFormat(value.cash_in_hand,true) }}</div>
                    </td>
                  </tr>
                </tbody>
              </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
    <Lottie :path="'/animate/loading_purple.json'" :title="'Loading'" :info="'Please wait...'" :show="showLottie" ref="lottieFunc" />
    <Common ref="commonFunc"/>
  </Layout>
</template>
<script>
  import Layout from "@/views/layouts/main";
  import PageHeader from "@/components/page-header";
  import appConfig from "@/app.config";
  import Lottie from "@/components/lottieView";
  import Common from "@/components/common";
  import axios from "axios";
  import Swal from "sweetalert2";
  import DatePicker from "vue2-datepicker";
  import Multiselect from "vue-multiselect";

  /**
   * Starter page
   */
  const PageTitle = "Earnings Report"
  const paymentGatewayCurrencyDefault = {
    "name":"Select Currency",
    "value":""
  }
  export default {
    components: { 
      Layout, 
      PageHeader, 
      Lottie,
      Common,
      Multiselect,
      DatePicker
    },
    page() {
      return {
        title: PageTitle,
        meta: [
          {
            name: "description",
            content: appConfig.description,
          },
        ],
      }
    },
    data() {
      return {
        defaultName: appConfig.defaultAccountName,
        accessToken:'',
        accessEmail:'',
        accessPhone:'',
        accessUsername:'',
        account_type:"",
        showLottie:false,
        updateData:{},
        title: '',
        items: [
          {
            text: appConfig.pageTitle,
            to: "/",
          },
          {
            text: '',
            active: true,
          },
        ],
        passwordFieldType_current: "password",
        classicon_current: "uil uil-eye-slash",
        passwordFieldType: "password",
        classicon: "uil uil-eye-slash",
        passwordFieldType2: "password",
        classicon2: "uil uil-eye-slash",
        paymentGatewayCurrency:[],
        selectedPaymentGatewayCurrency:{ "name": "Select Currency", "value": "" },
        loading:true,
        loading2:true,
        pg_balance:[],
        earnings_report:[],
        system_balance:[],
        date_range: null,
        disable_date: false,
        shortcuts: [],
        create_modify_permission:false
      };
    },
    middleware: "authentication",
    async mounted(){
     
      this.title = PageTitle
      this.items[1].text = PageTitle
      await this.reload()
      this.accessToken = this.$refs.commonFunc.getToken()
      this.accessUsername = this.$refs.commonFunc.getUsername()
      this.date_range = this.$refs.commonFunc.last7Days();
      this.shortcuts = this.$refs.commonFunc.datePickerShortcut();
      this.getPaymentGatewayCurrency();
      //this.getSystemBalanceData();
    }, 
    created(){
     
    },
    methods:{
      notBeforeToday(date) {
      return date > new Date(new Date().setHours(0, 0, 0, 0));
    },
    DateChanged() {
      console.log("datechange" + this.date_range);
      this.getEarningsBalance();
      this.disable_date = true;
    },
      convertCurrencyFormat(value,show00) {
        if (this.$refs.commonFunc){
          if (show00 == true) {
            return this.$refs.commonFunc.convertCurrencyFormat(value);
          } else {
            // Assuming this.transactionLimit is defined in your component's data
            return this.$refs.commonFunc
              .convertCurrencyFormat(value)
              .replaceAll(".00", "");
          }
        }
      },
     
      async getPaymentGatewayCurrency(){
          this.$Progress.start();
          this.loading = true;
          var bodyFormData = new FormData();
          bodyFormData.append("accessToken", this.accessToken);
          bodyFormData.append("accessUsername",  this.accessUsername);
          axios({
              method: "post",
              url: appConfig.APIHostAdmin + 'controller/admin/getSupportedCurrency',
              data: bodyFormData,
              headers: { "Content-Type": "multipart/form-data" },
          })
          .then((response)=>{
              var resData = response.data;
              if (resData.status == 200) {
                console.log(resData)
              
                this.selectedPaymentGatewayCurrency = [paymentGatewayCurrencyDefault]
                this.paymentGatewayCurrency = [];
                resData.data.forEach(element => {
                  this.paymentGatewayCurrency.push(element)
                });
                if (resData.data.length > 0){
                  this.selectedPaymentGatewayCurrency =  this.paymentGatewayCurrency[0]
                }
                this.getEarningsBalance();
                
              } 
              else if (resData.status == 440){
                Swal.fire(
                    {
                    icon: 'error',
                    title: 'Oops...',
                    html: `${resData.message}.`,
                    confirmButtonColor: '#222',
                    confirmButtonText: this.$t('siteLang.Done'),
                  })
                  localStorage.clear();
                  this.$router.push({
                      path: "/login",
                });
                this.loading = false;
              }
              else {
                  Swal.fire(
                    {
                    icon: 'error',
                    title: 'Oops...',
                    html: `${resData.message}.`,
                    confirmButtonColor: '#222',
                    confirmButtonText: this.$t('siteLang.Done'),
                  })
                  this.loading = false;
              }
              this.$Progress.finish();
          })
          .catch((error)=> {
              this.loading = false;
              this.$Progress.finish();
              //Swal.fire("Error", error, "error");
              Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${error}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
        });
      },
     
      async getEarningsBalance(){
          this.$Progress.start();
          this.loading = true;
          this.disable_date = true;
          this.earnings_report = [];
          var bodyFormData = new FormData();
          bodyFormData.append("accessToken", this.accessToken);
          bodyFormData.append("accessUsername",  this.accessUsername);
          bodyFormData.append("currency",  this.selectedPaymentGatewayCurrency.value);
          bodyFormData.append("fromDate", this.date_range[0]);
          bodyFormData.append("tillDate", this.date_range[1]);
          axios({
              method: "post",
              url: appConfig.APIHostAdmin + 'controller/report/getSystemEarningReport',
              data: bodyFormData,
              headers: { "Content-Type": "multipart/form-data" },
          })
          .then((response)=>{
              var resData = response.data;
              if (resData.status == 200) {
                console.log(resData)
                this.earnings_report = resData.data
              } 
              else if (resData.status == 440){
                Swal.fire(
                    {
                    icon: 'error',
                    title: 'Oops...',
                    html: `${resData.message}.`,
                    confirmButtonColor: '#222',
                    confirmButtonText: this.$t('siteLang.Done'),
                  })
                  localStorage.clear();
                  this.$router.push({
                      path: "/login",
                });
              }
              else {
                  Swal.fire(
                    {
                    icon: 'error',
                    title: 'Oops...',
                    html: `${resData.message}.`,
                    confirmButtonColor: '#222',
                    confirmButtonText: this.$t('siteLang.Done'),
                  })
              }
              this.loading = false;
              this.disable_date = false;
              this.$Progress.finish();
          })
          .catch((error)=> {
              this.loading = false;
              this.disable_date = false;
              this.$Progress.finish();
              //Swal.fire("Error", error, "error");
              Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${error}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
        });
      },
      async getSystemBalanceData(){
          this.$Progress.start();
          this.loading2 = true;
          this.system_balance = [];
          var bodyFormData = new FormData();
          bodyFormData.append("accessToken", this.accessToken);
          bodyFormData.append("accessUsername",  this.accessUsername);
          axios({
              method: "post",
              url: appConfig.APIHostAdmin + 'controller/report/getSystemBalanceReport',
              data: bodyFormData,
              headers: { "Content-Type": "multipart/form-data" },
          })
          .then((response)=>{
              var resData = response.data;
              if (resData.status == 200) {
                console.log(resData)
                this.system_balance = resData.data;
              } 
              else if (resData.status == 440){
                Swal.fire(
                    {
                    icon: 'error',
                    title: 'Oops...',
                    html: `${resData.message}.`,
                    confirmButtonColor: '#222',
                    confirmButtonText: this.$t('siteLang.Done'),
                  })
                  localStorage.clear();
                  this.$router.push({
                      path: "/login",
                });
              }
              else {
                  Swal.fire(
                    {
                    icon: 'error',
                    title: 'Oops...',
                    html: `${resData.message}.`,
                    confirmButtonColor: '#222',
                    confirmButtonText: this.$t('siteLang.Done'),
                  })
              }
              this.loading2 = false;
              this.$Progress.finish();
          })
          .catch((error)=> {
              this.loading2 = false;
              this.$Progress.finish();
              //Swal.fire("Error", error, "error");
              Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${error}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
        });
      },
      async reload() {
        try {
          await this.$nextTick(); // Wait for the component to be fully mounted
  
          const commonFunc = this.$refs.commonFunc;
          if (commonFunc && commonFunc.getFullData) {
            const data = await commonFunc.getFullData();
            this.account_type = data.account_type;
            const permission = data.modules.filter(e => e.name==='administrator')
            if (permission) {
              this.create_modify_permission = permission[0].create_modify_permission==0 ? false : true; 
              console.log(this.create_modify_permission)
            } else {
              console.log('Module with name "administrator" not found.');
            }
          } else {
            // Handle the case when commonFunc or getFullData is undefined
            // Perform appropriate actions or show a fallback message to the user
          }
        } catch (error) {
          // Handle the error silently without propagating it further
        }
      },
  
    }
  };
</script>
<style scoped>
  .custom-report tr td{
    padding:8px 12px;
  }
  .bg-inactive{
    background-color: #ecd4e5;
  }
  .multiselect{min-height: auto;}
  #eye_current,
  #eye,
  #eye2,
  #eye3{
  position: absolute;
  top: 4px;
  padding: 5px 10px;
  right: 5px;
  text-align: center;
  width: 32px;
  color: #999;
  z-index: 3;
  background-color: #fff;
  cursor: pointer;
  }
</style>